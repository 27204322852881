import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';
import round from 'lodash/round';

import { currencyCode } from '../../../Helpers/Geo/data';

class SpecialFormattedNumber extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    style: PropTypes.string,
    currency: PropTypes.string,
    className: PropTypes.string,
    dontConvert: PropTypes.bool,
    nativeRates: PropTypes.shape({}),
    hideDecimals: PropTypes.bool,
  };

  static defaultProps = {
    value: 0,
    style: 'currency',
    currency: null,
    className: '',
    dontConvert: false,
    nativeRates: {},
    hideDecimals: false,
  }

  render() {
    const { style, className, nativeRates, dontConvert, currency, hideDecimals } = this.props;
    let { value } = this.props;

    if (style !== 'currency') {
      return (
        <FormattedNumber
          className={className}
          value={value}
          style={style}
        />
      );
    }

    let decimals = Number(get(window, 'countryData.Currency-Minor-Unit', 2));

    // On order and receipt page we take currency from order, not from frontend setting
    let currencyToDisplay = currency || currencyCode;

    if (style === 'currency' && nativeRates.to && nativeRates.from === currencyToDisplay && !dontConvert) {
      currencyToDisplay = nativeRates.to;
      value *= nativeRates.rate;

      decimals = Number(get(window, 'countryData.Native-Currency-Minor-Unit', 2));
      value = round(value, decimals);
    }

    if (currencyToDisplay === 'RUB' || currencyToDisplay === 'JPY') {
      value = Number(value).toFixed(2);
      const totalArray = value.split('.');
      if (totalArray[1] === '00') {
        decimals = 0;
      }
    }

    if (hideDecimals) decimals = 0;

    return (
      <FormattedNumber
        minimumFractionDigits={decimals}
        className={className}
        value={value}
        style={style}
        currency={currencyToDisplay}
      />
    );
  }
}

const mapStateToProps = ({
  nativeRates,
}) => ({ nativeRates });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialFormattedNumber);
