import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Slider.scss';

export default function Slider({ children, interval }) {
  const [slideNumber, updateSlideNumber] = useState(0);
  const [slide, updateSlide] = useState(children[0]);

  /* eslint-disable react-hooks/exhaustive-deps */
  let timer;
  useEffect(() => {
    timer = setInterval(() => {
      const newSlideNumber = slideNumber === children.length - 1 ? 0 : slideNumber + 1;
      updateSlideNumber(newSlideNumber);
      updateSlide(children[newSlideNumber]);
    }, interval);
    return () => {
      timer = clearInterval(timer);
    };
  });

  let startPoint;
  const touchActionStart = (e) => {
    startPoint = e.touches[0].clientX;
    clearInterval(timer);
  };

  const touchActionEnd = (e) => {
    if (startPoint && e.changedTouches[0]) {
      if (startPoint > e.changedTouches[0].clientX) {
        const updateSlideLeft = slideNumber === 0 ? children.length - 1 : slideNumber - 1;
        updateSlideNumber(updateSlideLeft);
        updateSlide(children[updateSlideLeft]);
      }

      if (startPoint < e.changedTouches[0].clientX) {
        const updateSlideRight = slideNumber === children.length - 1 ? 0 : slideNumber + 1;
        updateSlideNumber(updateSlideRight);
        updateSlide(children[updateSlideRight]);
      }
    }
  };

  return (
    <div
      id="slider"
      onTouchStart={e => touchActionStart(e)}
      onTouchEnd={e => touchActionEnd(e)}
    >
      <div className="d-flex justify-content-center align-items-center align-self-center">
        {slide}
      </div>
    </div>
  );
}

Slider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  interval: PropTypes.number,
};

Slider.defaultProps = {
  children: [<React.Fragment />],
  interval: 3000,
};
