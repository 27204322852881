import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import env from '../../env';

function MoengageOptIn({ pathname, search, marketing, _id, email }) {
  const [hasSeen, setHasSeen] = useState(false);

  const isFandom = search.includes('utm_source=fandom');

  const initialise = () => {
    if (!window.Moengage && window.moe) {
      window.Moengage = window.moe({
        app_id: env.moEngageKey,
        debug_logs: env.moEngageDebug ? 4 : 0,
      });
    }
  };

  useEffect(() => {
    if (marketing && _id) {
      initialise();
      if (window.Moengage && window.Moengage.add_unique_user_id) {
        window.Moengage.add_unique_user_id(_id);
      }
    }
  }, [marketing, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (marketing) {
      initialise();
      if (window.Moengage && window.Moengage.add_email) {
        window.Moengage.add_email(email);
      }
    }
  }, [marketing, email]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (marketing && !hasSeen && !isFandom) {
      const moeDataRaw = localStorage.getItem('MOE_DATA');
      let moeData = {};
      if (moeDataRaw) {
        try {
          moeData = JSON.parse(moeDataRaw);
        } catch (e) {
          localStorage.removeItem('MOE_DATA');
        }
      }

      if (moeData.OPT_IN_SHOWN_TIME || moeData.SOFT_ASK_STATUS) {
        setHasSeen(true);
        return;
      }


      initialise();

      if (pathname === '/receipt' && window.Moengage && window.Moengage.call_web_push) {
        window.Moengage.call_web_push({
          soft_ask: true,
          main_class: 'moe-main-class',
          allow_class: 'moe-allow-class',
          block_class: 'moe-block-class',
        });
      }
    }
  }, [pathname, marketing]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!marketing) {
    return false;
  }

  return (
    <div className="moe-main-class" style={{ display: 'none' }}>
      <div className="moe-container">
        <img className="moe-logo" src="https://cdn.fanatical.com/production/brand/fanatical-icon.svg" loading="lazy" alt="Fanatical Icon" />
        <h5><FormattedMessage id="moe.title" defaultMessage="Fanatical About Deals?" /></h5>
        <p><FormattedMessage id="moe.desc" defaultMessage="Subscribe to browser notifications to get updates, deals and more!" /></p>
        <div className="moe-buttons">
          <button className="moe-block-class btn btn-secondary"><FormattedMessage id="moe.btn-block" defaultMessage="Don't Allow" /></button>
          <button className="moe-allow-class btn btn-primary"><FormattedMessage id="moe.btn-allow" defaultMessage="Allow" /></button>
        </div>
        <p className="moe-disclaimer"><FormattedMessage id="moe.disclaimer" defaultMessage="Notifications can be turned off anytime from your browser settings" /></p>
      </div>
    </div>
  );
}

MoengageOptIn.propTypes = {
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  _id: PropTypes.string,
  email: PropTypes.string,
  marketing: PropTypes.bool,
};

MoengageOptIn.defaultProps = {
  marketing: false,
  _id: null,
  email: null,
};

const mapStateToProps = ({
  router: { location: { pathname, search } },
  cookie: { consent: { marketing } },
  auth: { _id, email },
}) => ({
  pathname,
  search,
  marketing,
  _id,
  email,
});

export default connect(mapStateToProps, null)(MoengageOptIn);
