import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';

import store from '../../redux/store';

class StarDealButton extends React.Component {
  static propTypes = {
    starDeal: PropTypes.shape({}),
    className: PropTypes.string,
    parentClick: PropTypes.func,
    tag: PropTypes.string,
    isMobileNavTest: PropTypes.bool,
  }

  static defaultProps = {
    starDeal: null,
    className: '',
    parentClick: () => {},
    tag: 'a',
    isMobileNavTest: false,
  }

  handleClick = (link) => {
    if (this.props.parentClick) {
      this.props.parentClick();
    }

    store.dispatch({
      type: 'frontend/click/CLICK_INTERACTION',
      payload: { section: this.props.isMobileNavTest ? 'MobileNavBrowse' : 'SecondaryNavLinks', value: link },
    });
  }

  render() {
    const { className, starDeal, tag: Tag } = this.props;

    const slug = _get(starDeal, 'slug');
    const type = _get(starDeal, 'type');

    if (starDeal) {
      if (Tag !== 'a') {
        return (
          <Tag className={`nav-star-deal ${className}`} onClick={() => this.handleClick(`/${type}/${slug}`)}>
            <Link to={`/${type}/${slug}`}>
              <FormattedMessage id="nav.stardeal" defaultMessage="Star Deal" />
            </Link>
          </Tag>
        );
      }
      return (
        <Link
          to={`/${type}/${slug}`}
          className={className}
          onClick={() => this.handleClick(`/${type}/${slug}`)}
        >
          <div className="nav-star-deal star-deal-button">
            <FormattedMessage id="nav.stardeal" defaultMessage="Star Deal" />
          </div>
        </Link>
      );
    }

    return false;
  }
}


const mapStateToProps = ({
  starDeal,
}) => ({
  starDeal,
});

export default connect(mapStateToProps)(StarDealButton);
