import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export default class CountdownTimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.calculate();
  }

  componentDidMount() {
    clearInterval(this.interval);
    this.interval = setInterval(this.tick, this.props.int);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      return;
    }
    clearInterval(this.interval);
    this.interval = setInterval(this.tick, this.props.int);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculate() {
    const timeRemaining = (this.props.value - Date.now()) / 1000;
    const remainingMins = timeRemaining / 60;

    return {
      hours: Math.floor(remainingMins / 60),
      minutes: Math.floor(remainingMins % 60),
      seconds: Math.floor(timeRemaining % 60),
    };
  }

  tick = () => {
    const { hours, minutes, seconds } = this.state;

    if (this.props.value >= Date.now()) {
      this.setState(this.calculate());
    }

    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      clearInterval(this.interval);
    }
  }

  renderTime() {
    const { hours, minutes, seconds } = this.state;
    const { value, showZero, noLabel, timerOverride } = this.props;
    let timeHours = hours;
    let timeMinutes = minutes;
    let timeSeconds = seconds;

    if (hours > 48 && !timerOverride) {
      return (
        <b>
          {`${Math.floor(hours / 24)} `}
          <FormattedMessage id="countdownTimer.starDealDays" defaultMessage="Days" />
        </b>
      );
    }

    const now = new Date().getTime();
    if (now > value && showZero) {
      timeHours = 0;
      timeMinutes = 0;
      timeSeconds = 0;
    }
    if (hours <= 0 && minutes <= 0 && seconds <= 0 && showZero) {
      timeHours = 0;
      timeMinutes = 0;
      timeSeconds = 0;
    }

    return (
      <b className="timer-text">
        {timeHours < 10 ? `0${timeHours}` : timeHours}
        {!noLabel && <span className="label">h</span>}
        <span className="colon">:</span>
        {timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes}
        {!noLabel && <span className="label">m</span>}
        <span className="colon">:</span>
        {timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds}
        {!noLabel && <span className="label">s</span>}
      </b>
    );
  }

  render() {
    const { hours, minutes, seconds } = this.state;
    const { showZero, featuredDeal, checkoutTimer, bundleLaunchTimer } = this.props;

    if (featuredDeal) {
      if (hours <= 0 && minutes <= 0 && seconds <= 0 && !showZero) {
        return (
          <div className="featured-deal-timer">
            <FormattedMessage id="countdownTimer.starDealEnded" defaultMessage="Star Deal has ended" />
          </div>
        );
      }
      return (
        <div className="featured-deal-timer">
          <FormattedMessage id="countdownTimer.starDealEndsIn" defaultMessage="Ends in" />&nbsp;
          <span className={classNames({ 'ending-soon': hours < 2 })}>
            {this.renderTime()}&nbsp;
          </span>
          <FormattedMessage id="countdownTimer.starDealOrUntilSoldOut" defaultMessage="or until sold out" />
        </div>
      );
    }

    if (checkoutTimer) {
      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        return false;
      }
      return (
        <React.Fragment>
          <FormattedMessage id="countdownTimer.deal-ends" defaultMessage="Deal Ends: " />
          {this.renderTime()}
        </React.Fragment>
      );
    }

    if (bundleLaunchTimer) {
      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        return false;
      }
      return (
        <div className="section-heading-timer">
          <span className="timer-title">
            <FormattedMessage id="countdownTimer.bundleTimer.title" defaultMessage="Next Deal Live:" />
          </span>
          <span className="sd-timer">
            {this.renderTime()}
          </span>
        </div>
      );
    }

    if (hours <= 0 && minutes <= 0 && seconds <= 0) return false;

    return (
      <span className="sd-timer">
        {this.props.children &&
          <span>{this.props.children}&nbsp;</span>
        }
        {this.renderTime()}
      </span>
    );
  }
}

CountdownTimer.propTypes = {
  children: PropTypes.node,
  showZero: PropTypes.bool,
  value: PropTypes.number.isRequired,
  int: PropTypes.number,
  noLabel: PropTypes.bool,
  featuredDeal: PropTypes.bool,
  checkoutTimer: PropTypes.bool,
  bundleLaunchTimer: PropTypes.bool,
  timerOverride: PropTypes.bool,
};

CountdownTimer.defaultProps = {
  children: '',
  showZero: false,
  noLabel: false,
  featuredDeal: false,
  checkoutTimer: false,
  bundleLaunchTimer: false,
  timerOverride: false,
  int: 1000,
};
