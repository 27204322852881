import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class WishlistNavButton extends React.Component {
  static propTypes = {
    count: PropTypes.number,
    parentClick: PropTypes.func,
    authenticated: PropTypes.bool,
    wishlistLoaded: PropTypes.bool,
  }

  static defaultProps = {
    count: 0,
    parentClick: () => {},
    authenticated: false,
    wishlistLoaded: false,
  }

  handleClick = () => {
    if (this.props.parentClick) {
      this.props.parentClick();
    }
  }

  render() {
    const { count, authenticated, wishlistLoaded } = this.props;

    if (authenticated && !wishlistLoaded) {
      return (
        <Link to="/wishlist" className="nav-wishlist-btn" aria-label="Wishlist" onClick={this.handleClick}>
          <FontAwesomeIcon fixedWidth icon="heart" />
        </Link>
      );
    }

    return (
      <Link to="/wishlist" className="nav-wishlist-btn" aria-label="Wishlist" onClick={this.handleClick}>
        <FontAwesomeIcon fixedWidth icon="heart" />
        <span className={`nav-badge nav-badge-${count}`}>
          {count}
        </span>
      </Link>
    );
  }
}


const mapStateToProps = ({
  wishlist: { count, wishlistLoaded },
  auth: { authenticated },
}) => ({
  count,
  wishlistLoaded,
  authenticated,
});

export default connect(mapStateToProps)(WishlistNavButton);
