import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DropdownMenu from '../Utility/DropdownMenu';
import Dropdown from '../Utility/Dropdown';
import DropdownToggle from '../Utility/DropdownToggle';
import { history } from '../../redux/store';
import Logout from '../../containers/Auth/Logout';
import ThemeToggle from '../../containers/Utility/ThemeToggle';
import { isBulker } from '../../Helpers/User/Traits';

export default class LoginBtn extends React.Component {
  static propTypes = {
    authSummary: PropTypes.shape({
      authenticated: PropTypes.bool,
      email: PropTypes.string,
    }),
    toggleLoginModal: PropTypes.func,
    className: PropTypes.string,
    parentClick: PropTypes.func,
  }

  static defaultProps = {
    authSummary: {
      authenticated: false,
      email: '',
    },
    toggleLoginModal: {},
    className: '',
    parentClick: () => {},
  }

  state = {
    isLoggedIn: this.props.authSummary.authenticated,
    toggled: false,
  };

  UNSAFE_componentWillMount() {
    this.unlisten = history.listen(() => this.setState({ toggled: false }));
  }

  componentDidMount() {
    const _this = this;
    const element = document.getElementById('account-link');
    if (element && !_this.eventListenersAdded) {
      element.addEventListener('mouseover', () => {
        if (!this.state.toggled) {
          this.setState({ toggled: true });
        }
      });
      element.addEventListener('mouseleave', () => {
        this.setState({ toggled: false });
      });
      _this.eventListenersAdded = true;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authSummary.authenticated !== this.props.authSummary.authenticated) {
      const _this = this;
      const element = document.getElementById('account-link');
      if (element && !_this.eventListenersAdded) {
        element.addEventListener('mouseover', () => {
          if (!this.state.toggled) {
            this.setState({ toggled: true });
          }
        });
        element.addEventListener('mouseleave', () => {
          this.setState({ toggled: false });
        });
        _this.eventListenersAdded = true;
      }
    }
  }

  componentWillUnmount() {
    const _this = this;
    const element = document.getElementById('account-link');
    if (element && _this.eventListenersAdded) {
      element.removeEventListener('mouseover', () => {
        this.setState({ toggled: true });
      });
      element.removeEventListener('mouseleave', () => {
        this.setState({ toggled: false });
      });
    }
    this.unlisten();
  }

  eventListenersAdded = false;
  unlisten = () => {};

  handleClick = () => {
    if (this.props.parentClick) {
      this.props.parentClick();
    }
  }

  doLogin = () => {
    this.handleClick();
    this.props.toggleLoginModal();
  }

  toggle = () => {
    this.setState(prevState => ({ toggled: !prevState.toggled }));
  }

  render() {
    const { toggled } = this.state;
    const { authSummary: { authenticated, email }, className } = this.props;
    const isLoggedIn = authenticated;
    const showProductLibrary = !isBulker();

    return (
      <div className="d-flex w-100">
        {isLoggedIn &&
          <Dropdown
            isOpen={toggled}
            toggle={this.toggle}
            className="justify-content-end navbar-nav nav-item w-100"
            id="account-link"
          >
            <DropdownToggle
              nav
              caret
              className="nav-account-dropdown"
              onClick={this.handleLogoutClick}
            >
              <FormattedMessage id="nav.login.youraccount" defaultMessage="My Account" />
            </DropdownToggle>
            <DropdownMenu>
              <React.Fragment>
                <DropdownItem header className="text-muted">
                  <div className="mb-0">
                    <FormattedMessage
                      id="nav.login.loggedinas"
                      defaultMessage="Logged in as {emailAddress}"
                      values={{
                        emailAddress: <div className="pt-2 pb-2">{ email }</div>,
                      }}
                    />
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="acccount-dropdown-item" tag={Link} to="/account" onClick={this.handleClick}>
                  <FontAwesomeIcon icon="user" size="lg" />
                  <FormattedMessage id="nav.login.yourprofile" defaultMessage="Account Overview" />
                </DropdownItem>
                <DropdownItem className="acccount-dropdown-item" tag={Link} to="/orders" onClick={this.handleClick}>
                  <FontAwesomeIcon icon="key" size="lg" />
                  <FormattedMessage id="nav.login.orders" defaultMessage="Order History & Keys" />
                </DropdownItem>

                {showProductLibrary &&
                  <DropdownItem tag={Link} className="acccount-dropdown-item" to="/product-library" onClick={this.handleClick}>
                    <FontAwesomeIcon icon="book-open" size="lg" />
                    <FormattedMessage id="nav.login.product" defaultMessage="Product Library" />
                  </DropdownItem>
                }

                <DropdownItem className="acccount-dropdown-item" tag={Link} to="/account/wishlist" onClick={this.handleClick}>
                  <FontAwesomeIcon icon="heart" size="lg" />
                  <FormattedMessage id="nav.login.wishlist" defaultMessage="My Wishlist" />
                </DropdownItem>

                {!isBulker() &&
                  <DropdownItem className="acccount-dropdown-item" tag={Link} to="/reviews" onClick={this.handleClick}>
                    <FontAwesomeIcon icon="star" size="lg" />
                    <FormattedMessage id="nav.login.myreviews" defaultMessage="My Reviews" />
                  </DropdownItem>
                }

                <ThemeToggle place="account-dropdown" />
                <DropdownItem divider />
                <Logout tag={DropdownItem} className="acccount-dropdown-item">
                  <FontAwesomeIcon icon="sign-out-alt" size="lg" />
                  <FormattedMessage id="nav.login.logout" defaultMessage="Sign Out" />
                </Logout>
              </React.Fragment>
            </DropdownMenu>
          </Dropdown>
        }
        {!isLoggedIn &&
          <Button color="link" className={`${className} w-100`} onClick={this.doLogin}>
            <FormattedMessage id="nav.login.login" defaultMessage="Sign in" />
          </Button>
        }
      </div>
    );
  }
}
