import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import EmailSubscribeInput from '../../components/EmailSubscribe/EmailSubscribeInput';

import '../../components/Product/Usp/SmallUspBar.scss';

const EmailSubscribeBar = ({ mobileEnabled, appearsOn }) =>
  <div className="small-usp-bar">
    <div className="container d-flex justify-content-center align-items-center">
      <div className="d-flex justify-content-start align-items-center pt-6 pb-6" style={{ gap: 16 }}>
        <div className="d-flex justify-content-start align-items-center" style={{ gap: 8 }}>
          <FontAwesomeIcon icon={['fab', 'telegram-plane']} size="lg" style={{ color: '#FE9700' }} />
          {mobileEnabled ?
            <div style={{ color: 'black', fontSize: 14, fontFamily: 'Lato', fontWeight: '700', lineHeight: 21.60, wordWrap: 'break-word' }}>
              <FormattedMessage
                id="email-subscribe-bar.mobile-message"
                defaultMessage="Sign-up for Fanatical's Newsletter!"
              />
            </div> :
            <div style={{ color: 'black', fontSize: 18, fontFamily: 'Lato', fontWeight: '700', lineHeight: 21.60, wordWrap: 'break-word' }}>
              <FormattedMessage
                id="email-subscribe-bar.desktop-message"
                defaultMessage="Get great game deals! Sign-up for the Fanatical Newsletter now!"
              />
            </div>
          }
        </div>
        <div className="d-flex justify-content-start align-items-center" style={{ gap: 8 }}>
          <EmailSubscribeInput mobileEnabled={mobileEnabled} appearsOn={appearsOn}/>
        </div>
      </div>
    </div>
  </div>;

EmailSubscribeBar.propTypes = {
  mobileEnabled: PropTypes.bool,
};

EmailSubscribeBar.defaultProps = {
  mobileEnabled: false,
};

export default EmailSubscribeBar;
