import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import groupBy from 'lodash/groupBy';

import State from '../State';
import { post } from '../../api';
import GeneralUtils from '../GeneralUtils';
import { createOrder, giveawayOrder } from '../../redux/ducks/checkout';
import {
  toggleLoginModal,
  toggleGiveawayEmailRequiredModal,
  toggleVoucherEmailRequiredModal,
  toggleEpicGenbaModal,
  toggleSteamModal,
} from '../../redux/ducks/modal';
import {
  removeVoucher,
} from '../../redux/ducks/voucher';
import store, { history } from '../../redux/store';
import { refreshAuth } from '../../redux/ducks/auth';
import { newAPIPayment, resetAPIPayment } from '../../redux/ducks/apipayment';
import cartCalcSelector from '../../reselects/cartcalc';

const CheckoutUtils = {};

CheckoutUtils.creatingOrder = false;
CheckoutUtils.authChecking = false;

CheckoutUtils.authComplete = function authComplete() {
  if (!this.creatingOrder && this.authChecking) {
    this.authChecking = false;
    this.createPayment();
  }
};

CheckoutUtils.billingComplete = function billingComplete() {
  if (this.waitingForBilling) {
    this.waitingForBilling = false;
    this.createPayment();
  }
};

CheckoutUtils.getGiveawayProducts = function getGiveawayProducts() {
  const cartNotCartcalc = State.getState('cart');
  const giveawayProducts = [];
  cartNotCartcalc.items.forEach((item) => {
    if (item.giveaway) {
      if (item.type === 'bundle' && item.tierIndex === 0) {
        giveawayProducts.push(item);
      }
      if (item.type !== 'bundle') {
        giveawayProducts.push(item);
      }
    }
  });
  return giveawayProducts;
};

CheckoutUtils.getGiveawayProductIds = function getGiveawayProductIds() {
  return CheckoutUtils.getGiveawayProducts().map(item => item._id);
};

CheckoutUtils.handleAction = (adyenComponent, action, setError) => {
  if (adyenComponent && adyenComponent.handleAction) {
    return adyenComponent.handleAction(action);
  }
  if (action.type === 'redirect') {
    if (action.method === 'POST') {
      return CheckoutUtils.openWindowWithPostData(action.url, action.data);
    }

    return CheckoutUtils.openWindow(action.url);
  }
  return setError('PE006');
};

CheckoutUtils.handleNewSavedMethodSuccess = (orderId, paymentDetails, savedMethodOnSuccess) => {
  if (savedMethodOnSuccess) {
    return savedMethodOnSuccess(paymentDetails || orderId);
  }

  return history.replace(`/account/payment?paymentSuccess=${orderId}&recurringDetailReference=${get(paymentDetails, 'additionalData', {})['recurring.recurringDetailReference'] || ''}`);
};

CheckoutUtils.handleSuccess = (
  pspReference,
  resultCode,
  orderId,
) => {
  setTimeout(() => {
    store.dispatch(resetAPIPayment());
  }, 200);
  return history.replace(`/receipt?pspReference=${pspReference}&authResult=${resultCode.toUpperCase()}&merchantReference=${orderId}`);
};

CheckoutUtils.redirectToCheckout = (error) => {
  setTimeout(() => {
    store.dispatch(resetAPIPayment());
  }, 200);
  return history.replace(`cart?paymentError=${error}`);
};

CheckoutUtils.submitPayment = async (
  paymentContext,
  setError,
  type,
  paymentMethod = null,
  adyenComponent = null,
  setProcessingPayment = () => {},
  enableOneClick = false,
  savedCardId = null,
  returnResponse,
) => {
  const {
    fingerprint,
    order,
    shopperLocale,
    isNewSavedMethod,
    savedMethodOnSuccess,
    setSuccessMethod,
  } = paymentContext;

  store.dispatch({
    type: 'frontend/payment/SUBMIT_PAYMENT',
    payload: {
      method: type,
      orderId: order._id,
    },
  });

  setError(null);
  setProcessingPayment(true);
  if (setSuccessMethod) {
    setSuccessMethod(null);
  }

  const paymentData = {
    deviceFingerprint: fingerprint,
    orderId: order._id,
    shopperLocale,
    enableOneClick,
    browserInfo: get(adyenComponent, 'browserInfo'),
  };

  if (type === 'savedCard') {
    paymentData.savedCardId = savedCardId;
  }

  paymentData.type = type;
  paymentData.paymentDetails = paymentMethod || { type };

  const path = isNewSavedMethod ? '/user/payment/methods' : '/user/payment/payments';

  const { response, data: body } = await post(store.dispatch, path, paymentData);

  if (returnResponse) {
    return body;
  }

  if (response.ok && body.resultCode) {
    const { resultCode } = body;

    if (body.action) {
      return CheckoutUtils.handleAction(adyenComponent, body.action, setError);
    }

    setProcessingPayment(false);

    if (isNewSavedMethod) {
      return CheckoutUtils.handleNewSavedMethodSuccess(
        order._id,
        paymentData.paymentDetails,
        savedMethodOnSuccess,
      );
    }

    // TODO - Could return non-authorised results to user without redirecting
    return CheckoutUtils.handleSuccess(body.pspReference, resultCode || 'pending', order._id, paymentMethod, savedMethodOnSuccess);
  }

  if (body && body.error) {
    setProcessingPayment(false);
    if (body.redirect && body.redirect === 'cart') {
      return CheckoutUtils.redirectToCheckout(body.error);
    }
    return setError(body.error);
  }
  return setError('PE000');
};

CheckoutUtils.getPaymentDetails = async (
  payload,
  orderId,
  setError,
  adyenComponent = null,
  savedMethodOnSuccess,
  isRetry = false,
) => {
  const url = `/payment/get-payment-details/${orderId}`;
  const { response, data: body } = await post(store.dispatch, url, payload);

  if (response.ok) {
    if (body.action) {
      return CheckoutUtils.handleAction(adyenComponent, body.action, setError);
    }

    const pspReference = get(body, 'pspReference', 'NULL');
    const resultCode = get(body, 'resultCode', 'CANCELLED');

    if (orderId.includes('-save-')) {
      return CheckoutUtils.handleNewSavedMethodSuccess(
        orderId,
        body,
        savedMethodOnSuccess,
      );
    }

    // TODO - Could return non-authorised results to user without redirecting
    return CheckoutUtils.handleSuccess(pspReference, resultCode || 'pending', orderId, null, savedMethodOnSuccess);
  }

  if (body && body.error) {
    if (body.redirect && body.redirect === 'cart') {
      return CheckoutUtils.redirectToCheckout(body.error);
    }

    // Retry, just in case there was a problem at Adyen (socket hangup etc)
    if (body.error === 'PGD003' && !isRetry) {
      return setTimeout(() => CheckoutUtils.getPaymentDetails(
        payload,
        orderId,
        setError,
        adyenComponent,
        savedMethodOnSuccess,
        true,
      ), 1000);
    }

    return setError(body.error);
  }

  return setError('PGD000');
};

CheckoutUtils.checkIfLoggedIn = async function checkIfLoggedIn(page) {
  const auth = State.getState('auth');
  if (auth && !auth.authenticated) {
    store.dispatch(toggleLoginModal(page));
    this.authChecking = true;
    return false;
  }
  await store.dispatch(refreshAuth());
  return true;
};

CheckoutUtils.checkForEpicAccount = async function checkForEpicAccount(items) {
  const auth = State.getState('auth');

  if (items.find(item => item.source === 'epicGenba')) {
    if (auth && auth.epic && auth.epic.id) {
      return true;
    }

    store.dispatch(toggleEpicGenbaModal());
    return false;
  }
  return true;
};

CheckoutUtils.checkForSteam = function checkForSteam() {
  const steamGiveawayProducts = this.getGiveawayProducts().filter(i => i.giveaway_steam);
  if (steamGiveawayProducts.length === 0) {
    return true;
  }
  const auth = State.getState('auth');
  if (!get(auth, 'steam.steam_id') || get(auth, 'steam.isLimitedAccount')) {
    store.dispatch(toggleSteamModal());
    return false;
  }
  return true;
};

CheckoutUtils.checkForNewsletter = function checkForNewsletter() {
  let auth = State.getState('auth');
  const newsletterGiveawayProducts = this.getGiveawayProducts().filter(i => i.giveaway_newsletter);
  if (newsletterGiveawayProducts.length > 0) {
    if (
      auth &&
      (auth.email_newsletter || auth.localNewsletterConsent)
    ) {
      return true;
    }
    store.dispatch(toggleGiveawayEmailRequiredModal());
    return false;
  }
  const voucher = State.getState('voucher');
  const newsletter = get(voucher, 'voucher.newsletter');
  if (newsletter &&
    !(auth.email_newsletter || auth.localNewsletterConsent)
  ) {
    store.dispatch(toggleVoucherEmailRequiredModal());
    auth = State.getState('auth');
    if (auth.email_newsletter || auth.localNewsletterConsent) {
      return true;
    }
    return false;
  }
  return true;
};

CheckoutUtils.cancelAndRemoveVoucher = async function cancelAndRemoveVoucher() {
  await store.dispatch(removeVoucher());
  store.dispatch(toggleVoucherEmailRequiredModal());
};

CheckoutUtils.doAPIPayment = () => {
  history.push('/payment');
};

CheckoutUtils.doGiveawayPayment = (orderId, cart, giveawayProductIds) => {
  if (
    giveawayProductIds.length > 0 &&
    cart.items.length === giveawayProductIds.length
  ) {
    store.dispatch(giveawayOrder(orderId));
  }
};

CheckoutUtils.createPayment = async function createPayment() {
  this.creatingOrder = true;
  const state = State.getState();
  const cart = cartCalcSelector(state);
  const checkIfLoggedIn = await this.checkIfLoggedIn('checkout');
  if (!checkIfLoggedIn) {
    return false;
  }
  const checkForEpicAccount = await this.checkForEpicAccount(cart.items);
  if (!checkForEpicAccount) {
    return false;
  }

  this.creatingOrder = false;
  const checkForSteam = this.checkForSteam();
  if (!checkForSteam) {
    return false;
  }
  const checkForNewsletter = this.checkForNewsletter();
  if (!checkForNewsletter) {
    return false;
  }
  CheckoutUtils.continueToPayment();
  return true;
};

CheckoutUtils.continueToPayment = async function createPayment() {
  const lastSeenCoBranding = localStorage.getItem('lastSeenCoBranding');
  const today = new Date().getTime();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;
  const state = State.getState();
  const cart = cartCalcSelector(state);
  const voucher = state.voucher;
  const actualVoucher = get(voucher, 'voucher', {});
  let voucherCode = get(actualVoucher, 'code', '');
  const voucherText = get(voucher, 'voucherText', '');
  if (actualVoucher.discount_type === 'Account' || actualVoucher.discount_type === 'Unique') voucherCode = voucherText;
  const { locale } = window;
  const shopperLocale = GeneralUtils.getShopperLocale(locale);

  const orderOptions = {
    snowplowId: GeneralUtils.getSnowplowId(),
    ref_code: GeneralUtils.getReferalCode(),
    shopperLocale,
    giftOrder: State.getGiftOrderData(),
    cartcalcTotal: cart.total,
    localNewsletterConsent: state.auth.localNewsletterConsent,
  };
  const siteBrand = localStorage.getItem('siteBrand');
  if (siteBrand && lastSeenCoBranding && (today - Number(lastSeenCoBranding) < thirtyDays)) {
    orderOptions.siteBrand = siteBrand;
  }

  if (cart.total !== 0 || actualVoucher.type_of_discount === 'money') {
    orderOptions.code = voucherCode;
  }

  const giveawayProductIds = this.getGiveawayProductIds();
  const orderData = await store.dispatch(createOrder(orderOptions));

  if (orderData.type && orderData.type === 'billing') {
    this.waitingForBilling = true;
    store.dispatch({ type: 'frontend/checkout/BILLING_DETAILS_REQUESTED', payload: orderData.data.requiredFields });
    history.push('/billing');
    return true;
  }

  if (isEmpty(orderData.order) || !get(orderData, 'order._id', false)) {
    return false;
  }

  if (orderData.order.items.every(item => item.giveaway || get(item, 'report.free_product_id')) &&
    orderData.order.payment &&
    orderData.order.payment.total === 0) {
    return this.doGiveawayPayment(orderData.order._id, cart, giveawayProductIds);
  }

  const apiPaymentData = get(orderData, 'paymentData', {});

  if (apiPaymentData.billingFields) {
    store.dispatch({ type: 'frontend/checkout/BILLING_DETAILS_REQUESTED', payload: apiPaymentData.billingFields });
    // TODO on edit address mark as waitingForBilling or remove waiting for billing functionality
  }

  const orderTotal = get(orderData, 'order.payment.total', null);
  let cartcalcTotal = cart.total * 100;
  cartcalcTotal = Math.round(cartcalcTotal);

  if (cartcalcTotal !== orderTotal) {
    const payload = {
      hitType: 'unequalOrder',
      orderId: get(orderData, 'order._id', null),
      basketId: get(orderData, 'order.aid', null),
      userId: get(orderData, 'order.uid', null),
      cartTotal: cart.total,
      orderTotal: get(orderData, 'order.payment.total', null),
    };
    store.dispatch({ type: 'frontend/payment/ORDER_TOTAL_UNEQUAL_VERSUS_CARTCALC', payload });
  }

  store.dispatch(newAPIPayment(apiPaymentData));


  return this.doAPIPayment();
};

CheckoutUtils.openWindowWithPostData = (url, data) => {
  const form = document.createElement('form');
  form.target = '_self';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';
  form.enctype = 'application/x-www-form-urlencoded';

  const keys = Object.keys(data);

  keys.forEach((key) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

CheckoutUtils.openWindow = (url) => {
  window.open(url, '_self');
};

CheckoutUtils.toQuantityItems = (items) => {
  const returnItems = [];
  const groupedItemsObject = groupBy(items, '_id');
  items.forEach((item) => {
    const returnItem = Object.assign({}, item);
    returnItem.quantity = groupedItemsObject[item._id].length;
    returnItems.push(returnItem);
  });
  return returnItems;
};

CheckoutUtils.calculateAdyenObject = (order) => {
  let adyenAmount = get(order, 'payment.total', 0);
  adyenAmount = round(adyenAmount / 100, 2);
  const currencyCode = get(order, 'loc.currency_alphabetic_code', 'GBP');
  if (currencyCode === 'RUB' || currencyCode === 'JPY') {
    adyenAmount = Math.round(adyenAmount);
  }
  const adyenDiscount = get(order, 'report.funded_discount', 0);
  const items = CheckoutUtils.toQuantityItems(get(order, 'items', []));
  return {
    currency: currencyCode,
    amount: adyenAmount,
    items,
    discount: adyenDiscount,
  };
};

CheckoutUtils.getCompleteOrderTransaction = (items) => {
  const returnObject = { transactionTax: 0, transactionProducts: [] };
  (items || []).forEach((item) => {
    const newObject = {
      sku: '',
      name: '',
      category: '',
      price: 0,
      quantity: 0,
      local_price: 0,
      supplier_ids: [],
    };
    if (item._id) {
      newObject.sku = item._id;
    }
    if (item.name) {
      newObject.name = item.name;
    }
    if (item.supplier_id) {
      newObject.supplier_ids.push(item.supplier_id);
    }
    if (item.bundles.length) {
      item.bundles.forEach((tiers) => {
        tiers.games.forEach((game) => {
          newObject.supplier_ids.push(game.supplier_id);
        });
      });
    }
    if (item.type) {
      newObject.category = item.type;
    }
    if (item.display_type) {
      newObject.display_type = item.display_type;
    }
    let price = 0;
    const quantity = 1;
    if (item.report) {
      if (item.report.total) {
        price = item.report.total;
      }
      if (item.report.vat) {
        returnObject.transactionTax += item.report.vat;
      }
    }
    let localPrice = 0;
    if (item.payment) {
      if (item.payment.total) {
        localPrice = item.payment.total;
      }
    }
    newObject.price = price;
    newObject.itemTax = item.report.vat;
    newObject.quantity = quantity;
    newObject.local_price = localPrice;
    newObject.discountPercent = get(item, 'discount.percent');
    newObject.fullPrice = get(item, 'report.pickAndMixOriginalPrice'); // unusual - only reference to full price - used in other places in the system
    returnObject.transactionProducts.push(newObject);
  });
  return returnObject;
};

export default CheckoutUtils;
