import React, { useState, useEffect, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import Navbar from 'reactstrap/lib/Navbar';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';

import env from '../../env';
import { countryCode } from '../../Helpers/Geo/data';
import AutoSearchWrapper from './AutoSearch/AutoSearchWrapper';
import BrandAcer from './Brands/BrandAcer';
import BrandBox from './Brands/BrandBox';
import BrandBundlestars from './Brands/BrandBundlestars';
import BrandCcl from './Brands/BrandCcl';
import BrandChilliblast from './Brands/BrandChilliblast';
import BrandFanatical from './Brands/BrandFanatical';
import BrandGamespot from './Brands/BrandGamespot';
import BrandHpOmen from './Brands/BrandHpOmen';
import BrandInsidetech from './Brands/BrandInsidetech';
import BrandPowerColor from './Brands/BrandPowerColor';
import BrandRazer from './Brands/BrandRazer';
import BrandStormforce from './Brands/BrandStormforce';
import NavCartButton from '../../containers/Cart/NavCartButton';
import CheckoutSideBar from '../Checkout/CheckoutSideBar';
import GeneralUtils from '../../Helpers/GeneralUtils';
import getUrlParameter from '../../Helpers/Url/GetUrlParameter';
import GoogleOneTap from './GoogleOneTap';
import isBot from '../../Helpers/SEO/is-bot';
import LocalisationPrompt from './LocalisationPrompt';
import LoginBtn from '../../containers/NavBar/LoginBtn';
import MoengageOptIn from './MoengageOptIn';
import SecondaryNavBar from './SecondaryNavBar';
import SlimBannerContainer from '../Banner/SlimBannerContainer';
import store from '../../redux/store';
import UspBar from './UspBar';
import WishlistNavButton from '../../containers/Wishlist/WishlistNavButton';
import useViewPort from '../../Helpers/Hooks/useViewPort';
import MobileSideBar from './MobileNav/MobileSideBar';
import EmailSubscribeBar from './EmailSubscribeBar';

const MegaMenusContainer = lazy(() => import(/* webpackChunkName: "MegaMenus" */'./MegaMenu/MegaMenusContainer'));

export default function NavBar({
  pathname,
  authenticated,
  flashSale,
  siteBrand,
  isAdminLoggedInAs,
  email,
  emailNewsletter,
}) {
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [megaMenuTab, setMegaMenuTab] = useState('');
  const bannedCountires = ['AL', 'BH', 'BY', 'IM', 'MC', 'MD', 'PY', 'RU'];
  const userInCart = pathname === '/cart';
  const isHomePage = pathname === '/';
  const isFantasyVersePage = pathname === '/fantasyverse';

  useEffect(() => {
    if (!isBot()) {
      const lastSeenWelcomeBack = localStorage.getItem('lastSeenWelcomeBack');
      // const twentyFourHours = 10000; // 10 Seconds
      const twentyFourHours = 86400000;

      const pageDisabled = includes(window.location.href, '/cart') ||
        includes(window.location.href, '/receipt') ||
        includes(window.location.href, '/payment') ||
        includes(window.location.href, '/steam') ||
        !!getUrlParameter('v') ||
        getUrlParameter('add_to_basket');

      if (
        (!pageDisabled &&
          (!lastSeenWelcomeBack ||
            (lastSeenWelcomeBack && (Date.now() - lastSeenWelcomeBack) > twentyFourHours)))
        || getUrlParameter('always_show_checkout_bar')
      ) {
        setTimeout(() => {
          const reduxStore = store.getState();
          if (!isEmpty(reduxStore.cart.items)) {
            setShowWelcomeBack(true);
            localStorage.setItem('lastSeenWelcomeBack', Date.now());
          }
          // Waiting for cart items
        }, 1800);
      }
    }
  }, []);

  const hideNav = location.pathname && ((location.pathname.includes('/partners/hp-omen') && !location.pathname.includes('/partners/hp-omen-gaming-hub')) || location.pathname.includes('/welcome-new-user'));
  const simpleNav = false;

  const newUser = GeneralUtils.newUser();
  const googleOneTapUser = localStorage.getItem('isMobileGoogleOneTapLoginUser');
  const dismissedOneTap = localStorage.getItem('dismissedOneTap');
  // Checks if the users subscribed on the navbar within the last 24 hours
  // navSubscribedDate is in miliseconds
  const navSubscribedLast24hours = (Date.now() - localStorage.getItem('navSubscribedDate')) <= 1 * 24 * 60 * 60 * 1000;

  const toggleSearch = () => setShowMobile(!showMobile);

  const { isBelowLg, isAboveMd } = useViewPort();

  const showSecureCartTitle = userInCart && isBelowLg;

  return (
    <React.Fragment>
      {isAdminLoggedInAs &&
        <div className="admin-warning-bar">
          Warning - Admin Logged in as: {email}
        </div>
      }
      <div className={`${hideNav ? 'd-none' : ''}`}>
        {showWelcomeBack &&
          <CheckoutSideBar />
        }
        <LocalisationPrompt />

        {siteBrand === 'fanatical' &&
          <div>
            {(!emailNewsletter && (isHomePage || isFantasyVersePage))
              && !navSubscribedLast24hours ?
                <EmailSubscribeBar mobileEnabled={isBelowLg} appearsOn={isFantasyVersePage ? 'fantasyVerse' : 'home'} /> :
                <UspBar mobileEnabled={isBelowLg} />
            }
          </div>
        }

        <MoengageOptIn />
        <Navbar expand="md">
          <div className="w-100">
            <Container className="nav-container top-navbar">

              <div className="logo-container">
                {siteBrand === 'fanatical' &&
                  <BrandFanatical />
                }
                {siteBrand === 'bundlestars' &&
                  <BrandBundlestars />
                }
                {siteBrand === 'hpomen' &&
                  <BrandHpOmen />
                }
                {siteBrand === 'acer' &&
                  <BrandAcer />
                }
                {siteBrand === 'gamespot' &&
                  <BrandGamespot />
                }
                {siteBrand === 'chilliblast' &&
                  <BrandChilliblast />
                }
                {siteBrand === 'powercolor' &&
                  <BrandPowerColor />
                }
                {siteBrand === 'ccl' &&
                  <BrandCcl />
                }
                {siteBrand === 'box' &&
                  <BrandBox />
                }
                {siteBrand === 'razer' &&
                  <BrandRazer />
                }
                {siteBrand === 'insidetech' &&
                  <BrandInsidetech />
                }
                {siteBrand === 'stormforce' &&
                  <BrandStormforce />
                }

                <div className="tablet-search">
                  {showSecureCartTitle &&
                    <h1 className="checkout-title h5">
                      <FontAwesomeIcon icon="lock" className="lock-icon" />
                      <FormattedMessage id="navbar.your-cart" defaultMessage="Your Secure Cart" />
                    </h1>
                  }
                  {!simpleNav && !showSecureCartTitle &&
                    <React.Fragment>
                      <div className="d-none d-md-flex w-100">
                        <AutoSearchWrapper />
                      </div>
                      <div className="mobile-search-btn-container mobile-cart-items">
                        <NavLink
                          className="d-flex align-items-center"
                          onClick={toggleSearch}
                        >
                          <FontAwesomeIcon icon="search" size="lg" />
                        </NavLink>
                      </div>
                    </React.Fragment>
                  }

                  {!showSecureCartTitle &&
                    <React.Fragment>
                      <div className="mobile-cart-items">
                        <NavCartButton />
                      </div>
                      <MobileSideBar />

                    </React.Fragment>
                  }
                </div>

              </div>
              {!simpleNav &&
                <div className="d-none d-lg-block autosearch-nav navbar-nav">
                  <AutoSearchWrapper />
                </div>
              }
              <Nav navbar className="login-cart-container d-none d-lg-flex">
                <NavItem className="pr-0">
                  <div className="nav-top-links pr-2">
                    <LoginBtn className="sign-in-btn" />
                  </div>
                  <div className="cart-items">
                    <WishlistNavButton />
                  </div>
                  <div className="cart-items">
                    <NavCartButton showPrice />
                  </div>
                </NavItem>
              </Nav>

              {!authenticated && pathname !== '/cart' && (newUser || googleOneTapUser) && !dismissedOneTap && isAboveMd &&
                <GoogleOneTap />
              }
            </Container>

            <div className="w-100 nav-line d-none d-lg-block" />

            {!simpleNav &&
              <SecondaryNavBar
                authenticated={authenticated}
                flashSale={flashSale}
                location={location}
                setMegaMenuTab={setMegaMenuTab}
                megaMenuTab={megaMenuTab}
                siteBrand={siteBrand}
              />
            }
            <Suspense fallback={<div />}>
              <MegaMenusContainer
                setMegaMenuTab={setMegaMenuTab}
                megaMenuTab={megaMenuTab}
              />
            </Suspense>
          </div>
        </Navbar>


        {bannedCountires.includes(countryCode) &&
          <div className="prelive-warning-bar">
            <FormattedMessage id="navbar.warning.notabletoprocessspayment" defaultMessage="We are currently not able to process payments from this territory. We apologise for the inconvenience" />
          </div>
        }
        {(showMobile || pathname === '/search') &&
          <div className="d-block d-md-none mobile-search-dropdown">
            <AutoSearchWrapper show intialFocus setShowMobile={setShowMobile} />
          </div>
        }

        <React.Fragment>
          {includes(window.location.href, 'prelive.fanatical.com') &&
            <div className="prelive-warning-bar">
              Warning - This is prelive
            </div>
          }
          {(includes(window.location.href, 'staging.fanatical.com') || includes(window.location.href, 'staging2.fanatical.com')) &&
            <div className="prelive-warning-bar">
              Warning - This is staging
            </div>
          }
          {includes(window.location.href, 'testing.fanatical.com') &&
            <div className="prelive-warning-bar">
              Warning - This is testing
            </div>
          }
          {includes(window.location.href, 'localhost') && env.NodeEnv !== 'production' &&
            <div className="prelive-warning-bar">
              Warning - This is Dev
            </div>
          }
        </React.Fragment>

        <SlimBannerContainer />
      </div>
    </React.Fragment>
  );
}

NavBar.propTypes = {
  authenticated: PropTypes.bool,
  flashSale: PropTypes.shape({}),
  pathname: PropTypes.string,
  siteBrand: PropTypes.string,
  isAdminLoggedInAs: PropTypes.string,
  email: PropTypes.string,
};

NavBar.defaultProps = {
  authenticated: false,
  flashSale: {
    upcomingDeals: [],
    currentDeals: [],
    nextDeal: 0,
  },
  pathname: '',
  siteBrand: 'fanatical',
  isAdminLoggedInAs: '',
  email: '',
};
