import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import store from '../../redux/store';
import { getNativeCurrencyRate } from '../../redux/ducks/native-rates';
import { getByCode, publicFormat, findBrowserLang, getAll } from '../../config/lang';
import { LANGUAGE_MODAL, toggleLanguageModal } from '../../redux/ducks/modal';
import '../Languages/BrowserLang.scss';
import isBot from '../../Helpers/SEO/is-bot';
import LazyModal from '../../components/Utility/LazyModal';

const LanguagePickerModal = lazy(() => import(/* webpackChunkName: "LanguagePickerModal" */'../../components/Utility/LanguagePickerModal'));

class LocalisationPrompt extends React.Component {
  static propTypes = {
    toggleLanguageModal: PropTypes.func,
  }

  static defaultProps = {
    toggleLanguageModal: () => {},
  }

  state = { display: true, openModal: false };

  /**
   * Save the user's language and redirect or hide banner if necessary
   * @param {Object}  language language object
   */
  setLanguage = async (language) => {
    localStorage.setItem('language', JSON.stringify(language));

    const redirect = language.code !== window.locale;

    if (redirect) {
      await store.dispatch({
        type: 'frontend/languages/CHANGE_LANGUAGE',
        payload: {
          context: 'banner',
          from: window.locale,
          to: language.code,
        },
      });

      const rx = new RegExp(window.locale);
      const path = window.location.pathname.replace(rx, language.code);
      window.location.href = window.location.origin + path + window.location.search;
    } else {
      this.setState({ display: false });
    }
  }

  setCurrency = async (code) => {
    localStorage.setItem('displayCurrency', code);
    await store.dispatch(getNativeCurrencyRate());
    this.setState({ display: false });
  }

  /**
   * Find available native currency from country data
   * @return {Object|False}
   */
  getNativeCurrency = () => {
    const countryData = window.countryData || {};
    const hasNativePrice = countryData['Native-Currency-Alphabetic-Code'] && countryData['Native-Currency-Alphabetic-Code'] !== countryData['Currency-Alphabetic-Code'];

    if (hasNativePrice) {
      return {
        nativeCurrencyCode: countryData['Native-Currency-Alphabetic-Code'],
        nativeCurrencyName: countryData['Native-Currency-Name'],
      };
    }
    return false;
  }

  /**
   * User's selected currency from local storage
   * @return {String|False} [description]
   */
  getStoredCurrency = () => localStorage.getItem('displayCurrency') || false;

  hide = () => {
    this.setState({ display: false });
  }

  /**
   * Does the user have the native currency selected
   * @return {Boolean}
   */
  isCurrencySelected = () => this.getNativeCurrency() &&
    this.getNativeCurrency().nativeCurrencyCode === this.getStoredCurrency();

  /**
   * Does user have a selected language in local storage
   * @return {Boolean}
   */
  hasStoredLanguage = () => {
    const localLanguage = localStorage.getItem('language');
    return !!localLanguage && JSON.parse(localLanguage) && JSON.parse(localLanguage).code;
  }

  handleModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ openModal: !this.state.openModal });
    this.props.toggleLanguageModal();
  }

  handleClose = (e) => {
    if (e) e.preventDefault();
    this.setState({ openModal: false });
    this.props.toggleLanguageModal();
    this.hide();
  }

  render() {
    if (getAll().length < 2) {
      return false;
    }

    if (this.hasStoredLanguage() && this.getStoredCurrency()) {
      return false;
    }

    const countryData = window.countryData || {};

    const matched = findBrowserLang();
    const current = window.locale;
    const { bannerTranslations } = window;

    const matchedCurrency = this.getNativeCurrency();
    const isCurrencySelected = this.isCurrencySelected();

    const hasMatchedLang = matched && matched !== current && !this.hasStoredLanguage();

    const matchedLang = hasMatchedLang ? publicFormat(getByCode(matched)) : null;
    const currentLang = publicFormat(getByCode(current));

    const defaultCurrency = {
      currencyCode: countryData['Currency-Alphabetic-Code'],
      currencyName: countryData['Currency-Name'],
    };

    store.dispatch({
      type: 'frontend/languages/SHOW_PROMPT',
      payload: {
        context: 'banner',
        from: current,
        to: matched,
      },
    });

    const showCurrency = !hasMatchedLang && !!matchedCurrency && !isCurrencySelected;
    const showLanguage = hasMatchedLang && (!matchedCurrency || isCurrencySelected);
    const showBoth = hasMatchedLang && !!matchedCurrency && !isCurrencySelected;

    const show = !isBot() && this.state.display && (showCurrency || showLanguage || showBoth);

    if (!show) {
      return false;
    }

    return (
      <div className="lang-outer new">
        <Container className="lang-text-buttons-container">
          <IntlProvider locale={matched} messages={bannerTranslations[matched]}>
            {showCurrency &&
              <React.Fragment>
                <div className="text-container">
                  <FormattedMessage
                    id="browse-lang.fanatical-message-only-currency"
                    defaultMessage="Would you like to see Fanatical prices in approximate {newCurrencyName} ({newCurrencyCode})?"
                    values={{
                      newCurrencyName: matchedCurrency.nativeCurrencyName,
                      newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                    }}
                  />
                </div>

                <div className="buttons-container">
                  <div className="button-container">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.setCurrency(matchedCurrency.nativeCurrencyCode)}
                    >
                      <FormattedMessage
                        id="browse-lang.change-to-only-currency"
                        defaultMessage="Change to {newCurrencyCode}"
                        values={{
                          newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                        }}
                      />
                    </button>
                  </div>

                  <div className="button-container">
                    <button className="btn btn-sm btn-secondary" onClick={() => this.setCurrency(defaultCurrency.currencyCode)}>
                      <FormattedMessage
                        id="browse-lang.change-site-only-currency"
                        defaultMessage="Stay in {currentCurrencyCode}"
                        values={{
                          currentCurrencyCode: defaultCurrency.currencyCode,
                        }}
                      />
                    </button>
                  </div>

                  <div className="button-container">
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={(e) => { this.handleModal(e); }}
                    >
                      <FontAwesomeIcon icon="cog" />
                    </button>
                  </div>
                </div>
              </React.Fragment>
            }

            {showLanguage &&
              <React.Fragment>
                <div className="text-container">
                  <FormattedMessage
                    id="browse-lang.fanatical-message"
                    defaultMessage="Would you like to see Fanatical in {newLanguageLabel}?"
                    values={{
                      newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                    }}
                  />
                </div>

                <div className="buttons-container">
                  <div className="button-container">
                    <button className="btn btn-sm btn-secondary" onClick={() => this.setLanguage(matchedLang)}>
                      <FormattedMessage
                        id="browse-lang.change-to"
                        defaultMessage="Change to {newLanguageLabel}"
                        values={{
                          newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                        }}
                      />
                    </button>
                  </div>

                  <div className="button-container">
                    <button className="btn btn-sm btn-secondary" onClick={() => this.setLanguage(currentLang)}>
                      <FormattedMessage
                        id="browse-lang.change-site"
                        defaultMessage="Stay in {currentLanguageLabel}"
                        values={{
                          currentLanguageLabel: currentLang.nativeLabel,
                        }}
                      />
                    </button>
                  </div>
                </div>
              </React.Fragment>
            }

            {showBoth &&
              <React.Fragment>
                <div className="text-container">
                  <FormattedMessage
                    id="browse-lang.fanatical-message-with-currency"
                    defaultMessage="Would you like to see Fanatical in {newLanguageLabel} and approximate {newCurrencyName} ({newCurrencyCode})?"
                    values={{
                      newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                      newCurrencyName: matchedCurrency.nativeCurrencyName,
                      newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                    }}
                  />
                </div>

                <div className="buttons-container">
                  <div className="button-container">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        this.setCurrency(matchedCurrency.nativeCurrencyCode);
                        this.setLanguage(matchedLang);
                      }}
                    >
                      <FormattedMessage
                        id="browse-lang.change-to-with-currency"
                        defaultMessage="Change to {newLanguageLabel} and {newCurrencyCode}"
                        values={{
                          newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                          newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                        }}
                      />
                    </button>
                  </div>

                  <div className="button-container">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        this.setCurrency(defaultCurrency.currencyCode);
                        this.setLanguage(currentLang);
                      }}
                    >
                      <FormattedMessage
                        id="browse-lang.change-site-with-currency"
                        defaultMessage="Stay in {currentLanguageLabel} and {currentCurrencyCode}"
                        values={{
                          currentLanguageLabel: currentLang.nativeLabel,
                          currentCurrencyCode: defaultCurrency.currencyCode,
                        }}
                      />
                    </button>
                  </div>

                  <div className="button-container">
                    <button className="btn btn-sm btn-outline-secondary" onClick={e => this.handleModal(e)}>
                      <FontAwesomeIcon icon="cog" />
                    </button>
                  </div>
                </div>
              </React.Fragment>
            }
          </IntlProvider>
        </Container>
        <LazyModal
          open={this.state.openModal}
          setOpen={e => this.handleClose(e)}
          className="account-modal"
        >
          <LanguagePickerModal />
        </LazyModal>
      </div>
    );
  }
}

const mapStateToProps = ({
  modal: { [LANGUAGE_MODAL]: open },
  router: { location: { pathname, search } },
}) => ({ open, pathname, search });

const mapDispatchToProps = { toggleLanguageModal };

export default connect(mapStateToProps, mapDispatchToProps)(LocalisationPrompt);
