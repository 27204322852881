import React from 'react';
import PropTypes from 'prop-types';
import persistStore from 'redux-persist/lib/persistStore';
import get from 'lodash/get';

import { initialiseSplitTests } from '../../redux/ducks/split-test';
import { addVoucher } from '../../redux/ducks/voucher';
import { hydrateConsent, openCookieCollapsible } from '../../redux/ducks/cookie';
import { getOne } from '../../Helpers/Cookie/CookieUtils';
import isBot from '../../Helpers/SEO/is-bot';

export default class PersistStore extends React.Component {
  static propTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props);

    const runAfterLoad = () => {
      initialiseSplitTests();
      this.setState({ loaded: true });
      const state = props.store.getState();
      const today = new Date().toISOString();
      const validUntil = get(state, 'voucher.voucher.valid_until', false);
      const expiry = get(state, 'voucher.voucher.expiry', false);
      const voucherCode = get(state, 'voucher.voucher.code', false);
      if (validUntil && validUntil < today) {
        // Tried to export REMOVE_VOUCHER string from voucher
        // but it breaks combineReducers
        props.store.dispatch({ type: 'frontend/voucher/REMOVE_VOUCHER' });
      } else if (voucherCode && ((expiry && expiry < today) || !expiry)) {
        if (!(get(state, 'voucher.voucher.discount_type') === 'Account' && !get(state, 'auth.authenticated'))) {
          props.store.dispatch(addVoucher(voucherCode, get(state, 'voucher.voucher.discount_type') === 'Account'));
        } else {
          props.store.dispatch({ type: 'frontend/voucher/REMOVE_VOUCHER' });
        }
      }

      // Pull cookie consent from cookies instead of persisting through local storage
      const consentData = getOne('CookieConsent');
      if (consentData) {
        props.store.dispatch(hydrateConsent(consentData));
      } else {
        // If not found open cookie collapsible
        props.store.dispatch(openCookieCollapsible());
      }

      props.store.dispatch({ type: 'frontend/auth/RESET_AUTH' });
    };

    if (!isBot()) {
      persistStore(
        props.store,
        {
          whitelist: [
            'auth',
            'anonymous',
            'ageGating',
            'steam',
            'voucher',
          ],
          keyPrefix: 'bs',
        },
        runAfterLoad,
      );
    }
  }

  state = { loaded: isBot() }

  render() {
    if (!this.state.loaded) return false;
    return React.Children.only(this.props.children);
  }
}
